






















import { Component, Vue } from 'vue-property-decorator';
import DadosMarca from '@/components/dados/DadosMarca.vue';
import DadosModelo from '@/components/dados/DadosModelo.vue';
import DadosAno from '@/components/dados/DadosAno.vue';
import DadosNome from '@/components/dados/DadosNome.vue';
import DadosEmail from '@/components/dados/DadosEmail.vue';
import DadosDdd from '@/components/dados/DadosDdd.vue';
import DadosTelefone from '@/components/dados/DadosTelefone.vue';
import DadosProsseguir from '@/components/dados/DadosProsseguir.vue';
import Indicadores from '@/plugins/api/Indicadores';

@Component({
  components: {
    DadosAno,
    DadosModelo,
    DadosMarca,
    DadosNome,
    DadosEmail,
    DadosTelefone,
    DadosDdd,
    DadosProsseguir,
  },
})
export default class Dados extends Vue {
  public valid = false;

  get origemFipe() {
    return this.$store.state.origem_fipe;
  }

  public mounted() {
    this.$store.commit('SET_SIDEBAR_SHOWING', false);
    this.$store.commit('SET_SIDEBAR_ENABLED', false);

    const query = (this.$route.query as unknown) as DataLandingPage;

    if (query.simule !== undefined) {
      this.$store.direct.dispatch.SET_VENDEDOR(Number(query.simule));
    }
    if (query.ref !== undefined) {
      Indicadores.get(query.ref).then((data) => {
        this.$store.direct.commit.SET_COD_INDICADOR(parseInt(query.ref!, 10));
        this.$store.direct.commit.SET_ID_INDICADOR(data.usuario.id);
        this.$store.direct.commit.SET_INDICADOR(data.usuario.nome);
      });
    }
    if (query.fipe !== undefined && query.ano !== undefined) {
      this.$store.direct.dispatch.FROM_COD_ANO(query);
    }
  }
}
