
























import { Component, Vue } from 'vue-property-decorator';

@Component
export default class DadosMarca extends Vue {
  public created() {
    this.$store.direct.dispatch.LOAD_MARCAS();
  }

  get loadingMarcas() {
    return this.$store.state.loading_marcas;
  }

  get noDataText() {
    return this.loadingMarcas
      ? 'Carregando dados...'
      : 'Dados indisponíveis, verifique sua conexão com a internet';
  }

  get listaMarcas() {
    return this.$store.state.marcas;
  }

  get marca() {
    return this.$store.state.cotacao.marca;
  }

  set marca(value) {
    this.$store.direct.dispatch.SET_MARCA(value);
  }

  public getLogoMarca(value: string) {
    try {
      return require('@/assets/marcas/' + value + '.svg');
    } catch (e) {
      return require('@/assets/marcas/Desconhecida.svg');
    }
  }
}
