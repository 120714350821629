













import { Component, Vue } from 'vue-property-decorator';

@Component
export default class DadosModelo extends Vue {
  public rules = [(v: string) => !!v || 'Selecione uma Modelo'];

  get loadingModelos() {
    return this.$store.state.loading_modelos;
  }

  get noDataText() {
    return this.loadingModelos
      ? 'Carregando dados...'
      : 'Dados indisponíveis, verifique sem tem um marca selecionada';
  }

  get listaModelos() {
    return this.$store.state.modelos;
  }

  get modelo() {
    return this.$store.state.cotacao.modelo;
  }

  set modelo(value) {
    this.$store.direct.dispatch.SET_MODELO(value);
  }
}
