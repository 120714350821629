













import { Component, Vue } from 'vue-property-decorator';

@Component
export default class DadosAno extends Vue {
  get loadingAnos() {
    return this.$store.state.loading_anos;
  }

  get noDataText() {
    return this.loadingAnos
      ? 'Carregando dados...'
      : 'Dados indisponíveis, verifique se tem um modelo ';
  }

  get listaAnos() {
    return this.$store.state.anos;
  }

  get ano() {
    return this.$store.getters.veiculo_ano;
  }

  set ano(value) {
    this.$store.direct.dispatch.SET_ANO(value);
  }
}
